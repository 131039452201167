<template>
<div class="relative h-screen bg-gray-900 flex flex-col" :style="{
          backgroundImage: 'url(/img/throwbit_bot_background.svg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }">
    <Header />

    <div class="flex-1 flex flex-col items-center justify-start p-4 overflow-y-auto" :style="{ paddingTop: '5rem', marginBottom: '5rem' }">
        <img src="/img/planete/planete-2.svg" alt="Planet" class="w-40 h-40 md:w-68 md:h-68 mx-auto transform transition-transform duration-300 hover:scale-100 cursor-pointer" ref="planetImage" />

        <!-- Referral Link Section -->
        <div class="w-full max-w-2xl text-center my-4">
            <p class="text-white">Your Referral Link:</p>
            <div class="flex justify-center items-center mt-2 rounded-full">
                <input type="text" v-model="referralLink" readonly class="p-2 bg-[#11b5d8] text-[#ffffff] rounded-full mr-2 w-full max-w-xs" />
                <button @click="copyReferralLink" class="px-4 py-2 bg-[#ffffff] text-[#11b5d8] rounded-full ">
                    <i class="fas fa-copy text-xl"></i>
                </button>
            </div>
            <!-- Notification -->
            <p v-if="copySuccess" class="text-green-500 mt-2">Referral link copied!</p>
        </div>

        <!-- Friends List -->
        <div v-if="friends.length > 0" class="w-full sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg mx-auto">
            <ul class="space-y-4">
                <li v-for="friend in friends" :key="friend.id" class="bg-[#517f89] rounded-lg p-1 flex items-center shadow-lg transition-transform duration-300 hover:scale-105">
                    <div class="flex items-center w-full">
                        <!-- Avatar -->
                        <img src="https://api.dicebear.com/9.x/croodles-neutral/svg?flip=false" alt="Avatar" class="w-12 h-12 rounded-full mr-3" />
                        <!-- Name -->
                        <h2 class="text-white text-sm md:text-base">{{ friend.username }}</h2>
                    </div>
                </li>
            </ul>
        </div>
        <div v-else class="text-white mt-4">No referred friends found.</div>
    </div>
    <Navbar />
</div>
</template>

<script>
import Header from "@/components/Header.vue";
import Navbar from "@/components/Navbar.vue";
import UserReferalService from "@/api/service/userReferalService";

export default {
    components: {
        Header,
        Navbar,
    },
    data() {
        return {
            friends: [],
            page: "friend",
            referralLink: "",
            copySuccess: false,
            userId: null,
        };
    },
    mounted() {
        this.userId = sessionStorage.getItem("userId");
        if (this.userId) {
            this.fetchReferralData();
        } else {
            console.error("Utilisateur non authentifié. Aucun userId trouvé.");
        }
    },
    methods: {
        async fetchReferralData() {
            try {
                // Récupération du lien de parrainage
                const referralData = await UserReferalService.getReferralCode(this.userId);
                this.referralLink = referralData.referralLink;

                // Récupérer les amis référés
                const referralCode = referralData.referralLink.split("=").pop(); // Extraction du code
                const referredData = await UserReferalService.getReferredUsers(referralCode);
                this.friends = referredData.referredUsers;
            } catch (error) {
                console.error("Erreur lors de la récupération des données :", error.message);
            }
        },
        copyReferralLink() {
            navigator.clipboard.writeText(this.referralLink).then(() => {
                this.copySuccess = true;
                setTimeout(() => {
                    this.copySuccess = false;
                }, 1000);
            });
        },
    },
};
</script>
