<template>
<div class="relative h-screen bg-gray-900 flex flex-col" :style="{
        backgroundImage: 'url(/img/throwbit_bot_background.svg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }">
    <Header />

    <div class="flex-1 flex flex-col items-center justify-start p-4 overflow-y-auto" :style="{ paddingTop: '5rem', marginBottom: '5rem' }">
        <img src="/img/planete/planete-2.svg" alt="Planet" class="w-48 h-48 md:w-72 md:h-72 mx-auto transform transition-transform duration-300 hover:scale-110 cursor-pointer" ref="planetImage" />
        <div class="w-full sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg mx-auto">
            <ul class="space-y-4">
                <li v-for="airdrop in airdrops" :key="airdrop.id" class="bg-[#517f89] rounded-xl p-2 flex justify-between items-center shadow-lg transition-transform duration-300 hover:scale-105" :class="{ 'bg-[#7eb5c1] text-[#ffffff]': airdrop.claimed }">
                    <div class="flex items-center">
                        <h2 class="text-lg font-semibold text-white">{{ airdrop.title }}</h2>
                        <span class="text-[#5ff8f1] font-bold ml-8 mr-8">{{ Math.round(airdrop.amount) }} TH</span>
                    </div>
                    <button @click="claimAirdrop(airdrop.id)" class="px-5 py-1 bg-blue-500 text-white rounded-full hover:bg-[#90b9c2] transition duration-300" :class="{ 'bg-[#90b9c2] text-[#ffffff]': airdrop.claimed }" :disabled="airdrop.claimed">
                        {{ airdrop.claimed ? "Claimed" : "Claim" }}
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <Navbar/>
</div>
</template>

  
  
<script>
import Header from "@/components/Header.vue";
import Navbar from "@/components/Navbar.vue";
import {
    toast
} from "vue3-toastify";

export default {
    components: {
        Header,
        Navbar,
    },
    data() {
        return {
            airdrops: [{
                    id: 1,
                    title: "Level 1",
                    amount: 10000,
                    claimed: false
                },
                {
                    id: 2,
                    title: "Level 2",
                    amount: 50000,
                    claimed: false
                },
                {
                    id: 3,
                    title: "Level 3",
                    amount: 1000,
                    claimed: true
                },
                {
                    id: 4,
                    title: "Level 3",
                    amount: 1000,
                    claimed: false
                },
                {
                    id: 5,
                    title: "Level 3",
                    amount: 1000,
                    claimed: false
                },
                {
                    id: 6,
                    title: "Level 3",
                    amount: 1000,
                    claimed: false
                },
            ],
            page: "airdrops",
        };
    },
    methods: {
        claimAirdrop(airdropId) {
            const airdrop = this.airdrops.find((a) => a.id === airdropId);
            if (airdrop && !airdrop.claimed) {
                airdrop.claimed = true; // Update the claimed status
                toast.success(`${airdrop.title} claimed successfully!`, {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else if (airdrop && airdrop.claimed) {
                toast.info(`${airdrop.title} has already been claimed.`, {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else {
                toast.error("Airdrop not found!", {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
        },
    },

};
</script>
  