<template>
<div class="relative h-screen bg-gray-900 flex flex-col" :style="{
        backgroundImage: 'url(/img/throwbit_bot_background.svg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }">
    <Header />

    <div class="flex-1 flex flex-col items-center justify-start p-4 overflow-y-auto" :style="{ paddingTop: '5rem', marginBottom: '1rem' }">
        <h1 class="text-4xl font-extrabold text-white mb-4 mt-0 tracking-wide text-center">
            {{ points }} TH
        </h1>
        <h4 class="text-white mb-4 mt-0 tracking-wide text-center">
            Level 1 {{ userName }}
        </h4>
        <!-- Planet image with small indicators -->
        <div class="relative mb-2">
            <img src="/img/planete-2.svg" alt="Planet" class="w-48 h-48 md:w-72 md:h-72 mx-auto transform transition-transform duration-300 hover:scale-110 cursor-pointer no-context-menu" @mousedown="startCollecting" @mouseup="stopCollecting" @mouseleave="stopCollecting" @touchstart="startCollecting" @touchend="stopCollecting" @contextmenu.prevent />

            <transition-group name="floating" tag="div">
                <div v-for="(float, index) in floatingNumbers" :key="index" class="absolute text-lg font-bold text-blue-300 floating-text" :style="{ top: float.y + 'px', left: float.x + 'px' }">
                    +{{ float.value }}
                </div>
            </transition-group>
        </div>
        <!-- Barre de progression -->
        <div class="w-full max-w-xs">
            <div class="relative pt-1">
                <div class="flex items-center justify-between">
                    <!-- Afficher les pourcentages calculés -->
                    <span class="text-sm text-white">{{ Math.floor(progressPercentage) }}%</span>
                    <span class="text-sm text-white">100%</span>
                </div>
                <div class="relative pt-1">
                    <div class="flex">
                        <div class="w-full bg-gray-700 rounded-full">
                            <!-- Appliquer la largeur en fonction des pourcentages -->
                            <div class="bg-[#3291e1] text-xs font-medium text-blue-100 text-center p-1 leading-none rounded-full" :style="{ width: progressPercentage + '%', height: '10px' }"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Informations sur la recharge -->
        <div v-if="isRecharging" class="relative flex items-center justify-between w-full p-2 space-x-1">
            <span class="text-yellow-400 text-sm">⚡ {{ rechargeRate.toFixed(2) }} </span>

            <span class="text-gray-200 text-sm">
                {{ formattedIdlePoints }} / {{ maxIdlePoints }}
            </span>

            <!-- Temps restant formaté -->
            <span class="text-gray-400 text-sm">{{ formattedTimeRemaining }}</span>
        </div>
        <p v-if="rechargeCompletedMessage">{{ rechargeCompletedMessage }}</p>
        <p v-if="collectionCompletedMessage">{{ collectionCompletedMessage }}</p>
    </div>

    <button @click="resetLocalStorage" class="bg-red-500 text-white px-6 py-3 rounded-full hover:bg-red-600 focus:outline-none focus:ring-4 focus:ring-red-300 transition-all duration-200">
        Reset
    </button>

    <!-- Barre de progression -->
    <div class="w-full max-w-xs">
        <div class="relative pt-1">
            <div class="flex items-center justify-between">
                <span class="text-sm text-white">{{ Math.floor(progressPercentage) }}%</span>
                <span class="text-sm text-white">100%</span>
            </div>
            <div class="relative pt-1">
                <div class="flex">
                    <div class="w-full bg-gray-700 rounded-full">
                        <div class="bg-[#3291e1] text-xs font-medium text-blue-100 text-center p-1 leading-none rounded-full" :style="{ width: progressPercentage + '%', height: '10px' }"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<LastActivity />
<Navbar />
</template>

<script>
import UserServices from "@/api/service/userService";
import UserTasksService from "@/api/service/userTasksService";
import Header from "@/components/Header.vue";
import LastActivity from "@/components/LastActivity.vue";
import Navbar from "@/components/Navbar.vue";
import {
    toast
} from "vue3-toastify";

const userTasksService = new UserTasksService();
const userRewardService = new UserServices();

export default {

    components: {
        Header,
        Navbar,
    },
    data() {
        return {
            points: 0,
            idlePoints: 0,
            maxIdlePoints: 100, //Nombre maximal de point a collecter
            progress: 0,
            upgradeLevel: 0.5, //quantité de points gagnés à chaque interaction
            lastRechargeTime: Date.now(),
            elapsedTime: 0,
            totalRechargeTime: 1800, // la durre de recharge

            floatingNumbers: [],

            rechargeCompletedMessage: "",
            collectionCompletedMessage: "",
            page: "home",
            userName: "",
            notification: "",
            notificationType: "",

            collecting: false,
            isRecharging: false,
        };
    },

    computed: {
        rechargeRate() {
            return this.maxIdlePoints / this.totalRechargeTime; // Calcul de la vitesse de recharge
        },

        formattedIdlePoints() {
            return this.idlePoints === this.maxIdlePoints ?
                this.idlePoints :
                this.idlePoints.toFixed(2);
        },

        progressPercentage() {
            return ((this.idlePoints / this.maxIdlePoints) * 100).toFixed(3);
        },

        timeRemaining() {
            return Math.max(this.totalRechargeTime - this.elapsedTime, 0);
        },

        formattedTimeRemaining() {
            const hours = Math.floor(this.timeRemaining / 3600);
            const minutes = Math.floor((this.timeRemaining % 3600) / 60);
            const seconds = Math.floor(this.timeRemaining % 60);
            return `${String(hours).padStart(2, "0")}h : ${String(minutes).padStart(
        2,
        "0"
      )}m : ${String(seconds).padStart(2, "0")}s`;
        },
    },

    methods: {
        startCollecting() {
            // Empêche la collecte si la recharge est en cours
            // if (!this.isRecharging) {
            this.collecting = true;
            this.collectionCompletedMessage = "";
            this.collectPoints();
            // } else {
            // toast.error(`Veuillez attendre après ${this.formattedTimeRemaining}`);
            // }
        },

        stopCollecting() {
            if (this.collecting) {
                this.collecting = false;
                // this.startRecharging();
            }
        },

        clearLocalStorage() {
            localStorage.removeItem("points");
            localStorage.removeItem("idlePoints");
            localStorage.removeItem("lastRechargeTime");
            localStorage.removeItem("elapsedTime");
        },

        async collectPoints() {
            if (this.collecting && this.idlePoints > 0) {
                this.points += this.upgradeLevel;
                this.idlePoints = Math.max(this.idlePoints - this.upgradeLevel, 0);
                this.elapsedTime = Math.min(
                    this.elapsedTime + this.upgradeLevel,
                    this.totalRechargeTime
                ); // Incrémente le temps de recharge pendant la collecte
                this.animateProgress();

                console.log(this.points);

                if (this.idlePoints <= 0) {
                    this.collecting = false;
                    this.collectionCompletedMessage =
                        "Collecte terminée! Tous les points ont été collectés.";
                    await this.updatedPointEarned();
                    this.clearLocalStorage();
                    this.startRecharging();
                } else {
                    setTimeout(this.collectPoints, 100);
                }
            }
        },

        startRecharging() {
            this.lastRechargeTime = Date.now();
            this.rechargeCompletedMessage = "";
            this.elapsedTime = 0;
            this.isRecharging = true;
        },

        animateProgress() {
            this.progress = 10;
            this.floatingNumbers.push({
                value: this.progress,
                x: Math.random() * 80 + 10,
                y: Math.random() * 80 + 10,
            });
            setTimeout(() => this.floatingNumbers.shift(), 2000);
        },

        rechargeIdPoints() {
            this.isRecharging = true;
            const currentTime = Date.now();
            const timeElapsed = (currentTime - this.lastRechargeTime) / 1000; // en secondes
            const pointsToRecharge = timeElapsed * this.rechargeRate;

            if (this.idlePoints < this.maxIdlePoints) {
                this.idlePoints = Math.min(
                    this.idlePoints + pointsToRecharge,
                    this.maxIdlePoints
                );
                this.elapsedTime = Math.min(
                    this.elapsedTime + timeElapsed,
                    this.totalRechargeTime
                );
            } else {
                this.isRecharging = false;
                this.rechargeCompletedMessage = "Recharge terminée!";
            }

            this.lastRechargeTime = currentTime;
            this.saveState(); // Sauvegarde l'état après chaque recharge
        },

        saveState() {
            localStorage.setItem(
                "gameState",
                JSON.stringify({
                    points: this.points,
                    idlePoints: this.idlePoints,
                    lastRechargeTime: this.lastRechargeTime,
                    elapsedTime: this.elapsedTime,
                })
            );
        },

        loadState() {
            const savedState = JSON.parse(localStorage.getItem("gameState"));
            if (savedState) {
                this.points = savedState.points;
                this.idlePoints = savedState.idlePoints;
                this.lastRechargeTime = savedState.lastRechargeTime;
                this.elapsedTime = savedState.elapsedTime;
                const currentTime = Date.now();
                const timeElapsed = (currentTime - this.lastRechargeTime) / 1000;
                this.elapsedTime = Math.min(
                    this.elapsedTime + timeElapsed,
                    this.totalRechargeTime
                );
                this.rechargeIdPoints();
            }
        },

        async updatedPointEarned() {
            this.maxPointsReached = false;
            console.log(this.points);
            const data = {
                userId: sessionStorage.getItem("userId") || 85554,
                telegramConnectionId: sessionStorage.getItem("userId") || 34446,
                // rewardType: "level 1",
                rewardValue: this.points,
            };

            try {
                console.log(data);
                const userReward = await userRewardService.userCollectReward(data);
                console.log(userReward);
                this.points = 0;
                toast.success(userTasks.message);
            } catch (error) {
                console.error("Erreur réseau :", error.message);
            }
        },

        async fetchUserTasks() {
            try {
                const userTasks = await userTasksService.getUserTasks();
                this.pointsEarned = userTasks ?.totalPoints || 0;
            } catch (error) {
                console.error("Erreur réseau :", error.message);
            }
        },
    },

    mounted() {
        this.userName = sessionStorage.getItem("userId");
        this.fetchUserTasks();
        this.loadState();
        setInterval(this.rechargeIdPoints, 1000);
        window.addEventListener("beforeunload", this.saveState);
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this.saveState);
    },
};
</script>

<style scoped>
.floating-enter-active,
.floating-leave-active {
    transition: opacity 1s, transform 1s;
}

.floating-enter,
.floating-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}

.floating-text {
    position: absolute;
    animation: rise 1s ease forwards;
}

@keyframes rise {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-30px);
    }
}

.no-context-menu {
    user-select: none;
    pointer-events: auto;
    -webkit-user-drag: none;
    touch-action: none;
}
</style>
