<template>
<div class="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32" :style="{
      backgroundImage: 'url(/img/throwbit_bot_background.svg)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }">
    <br />
    <img src="/img/planete-1.svg" alt="Planet Background" class="absolute inset-0 -z-10 w-1/2 max-w-md h-auto object-cover object-center mx-auto animate-pulse" />
    <br /><br /><br /><br />
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0 text-center lg:text-left">
            <h2 class="text-4xl font-extrabold tracking-tight text-white sm:text-6xl drop-shadow-lg">
                Welcome to Throwbit space🚀
            </h2>
            <p class="mt-6 text-lg leading-8 text-gray-300 drop-shadow-md tracking-wide font-[Lora]">
                Tap on the planets to start your adventure! Compete with friends and reach for
                the stars in this exciting game.
            </p>

            <!-- Loader -->
            <div v-if="isAuthenticating" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <svg class="animate-spin h-12 w-12 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"></path>
                </svg>
            </div>

            <div v-if="user">
                <p><strong>User:</strong> {{ user.first_name }} {{ user.last_name }}</p>
                <p><strong>Photo:</strong></p>
                <img :src="user.photo_url" alt="User Photo" class="w-12 h-12 rounded-full border-2 border-white" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "@/api/axios.js";
import {
    toast
} from "vue3-toastify";

export default {
    data() {
        return {
            telegramInfo: null,
            user: null,
            authToken: null,
            isAuthenticating: false,
        };
    },
    methods: {
        getTelegramData() {
            if (window.Telegram && window.Telegram.WebApp) {
                const webApp = window.Telegram.WebApp;
                webApp.ready();

                this.telegramInfo = {
                    platform: webApp.platform,
                    colorScheme: webApp.colorScheme,
                };

                const initData = webApp.initData;
                if (initData) {
                    try {
                        const params = new URLSearchParams(initData);

                        // Informations utilisateur
                        const user = JSON.parse(params.get("user"));
                        this.user = {
                            id: user.id,
                            first_name: user.first_name,
                            last_name: user.last_name,
                            username: user.username,
                            language_code: user.language_code,
                            photo_url: user.photo_url,
                            auth_date: params.get("auth_date"),
                            hash: params.get("hash"),
                            chat_type: params.get("chat_type"),
                            chat_id: params.get("chat_id"),
                            is_bot: params.get("is_bot") === "true",
                        };
                    } catch (error) {
                        console.error("Erreur lors de l'extraction des données Telegram :", error);
                        toast.error("Erreur lors de l'extraction des données Telegram !");
                    }
                } else {
                    console.warn("Aucune donnée utilisateur trouvée !");
                    toast.warning("Aucune donnée utilisateur trouvée !");
                }
            } else {
                console.error("Telegram WebApp n'est pas détecté !");
                toast.error("Telegram WebApp n'est pas détecté !");
            }
        },

        async loginUser(telegramUserId) {
            this.isAuthenticating = true;

            try {
                const response = await axios.post("/telegram-login", {
                    telegramUserId,
                });

                // Enregistrer toutes les informations dans la sessionStorage
                const {
                    token,
                    user
                } = response.data;
                sessionStorage.setItem("authToken", token);
                sessionStorage.setItem("userId", user.id);
                sessionStorage.setItem("username", user.username);
                sessionStorage.setItem("firstName", user.firstName);
                sessionStorage.setItem("lastName", user.lastName);
                toast.success("Utilisateur authentifié avec succès !");
                this.$router.push("/start");
            } catch (error) {
                console.error("Erreur lors de la connexion de l'utilisateur :", error);
                toast.error("Erreur lors de la connexion de l'utilisateur !");
            } finally {
                this.isAuthenticating = false;
            }
        },
    },
};
</script>
