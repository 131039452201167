import axiosClient from "../axios"; // Assurez-vous que le chemin est correct

class UserTasksService {
    // Methode  re recuperation de toutes les tâches
    static async getTasks() {
        try {
            const response = await axiosClient.get("/get-all-task");
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Erreur lors de la récupération des tâches : ' + response.data.message);
            }
        } catch (error) {
            if (error?.code === "ERR_NETWORK" || error?.message === "Network Error") {
                const messageError = error?.code || error?.message;
                console.log(messageError);
            } else {
                console.log(error);
            }
        }
    }

    // Methode  de recuperation de toutes les tâches utilisateur
    async getUserTasks() {
        try {
            const response = await axiosClient.post('get-all-user-task-reward');
            if (response.status === 200) {
                return response.data; // Retourne les données en cas de succès
            } else {
                throw new Error('Erreur lors de la récupération : ' + response.data.message);
            }
        } catch (error) {
            console.error("Erreur dans getTasks:", error);
            throw error; // Relance l'erreur pour la gestion dans le composant
        }
    }
    //  Methode de validation d'une tâche utilisateur
    async addUserSuccesstasks(data) {
        try {
            const response = await axiosClient.post("/add-task-reward/", data);
            if (response.status === 200 || response.status === 201) {
                return response.data;
            } else {
                console.log(response.response.data.message);
                throw new Error('Erreur lors de la validate : ' + response.data.message);
            }
        } catch (error) {
            if (error?.code === "ERR_NETWORK" || error?.message === "Network Error") {
                const messageError = error?.code || error?.message;
                console.log(messageError);
            } else {
                console.log(error);
            }
        }
    }
    // Methode de recuperation des tâches lié a un utilisteur et le pont total colecter.
    async getUserTasks() {
        try {
            const response = await axiosClient.get("/get-user-task-reward", {
                params: {
                    id: parseInt(sessionStorage.getItem("userId")) || 2
                }
            });
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Erreur lors de recuperation : ' + response.data.message);
            }
        } catch (error) {
            if (error?.code === "ERR_NETWORK" || error?.message === "Network Error") {
                const messageError = error?.code || error?.message;
                console.log(messageError);
            } else {
                console.log(error);
            }
        }
    }

    // Mise à jour les points collectés
    async sendCollectedPoint(points) {
        try {
            const response = await axiosClient.post("/get-user-task-reward", {
                collectedPoints: points,
                id: userId
            });

            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('Erreur lors de recuperation : ' + response.data.message);
            }
        } catch (error) {
            if (error?.code === "ERR_NETWORK" || error?.message === "Network Error") {
                const messageError = error?.code || error?.message;
                console.log(messageError);
            } else {
                console.log(error);
            }
        }
    }
}

export default UserTasksService;
