<template>
    <div class="relative h-screen bg-gray-900 flex flex-col" :style="{
          backgroundImage: 'url(/img/throwbit_bot_background.svg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
      }">
      <!-- Header -->
      <Header />
  
      <!-- Scrollable Section -->
      <div class="flex-1 flex flex-col items-center justify-start p-4 overflow-y-auto" :style="{ paddingTop: '5rem', marginBottom: '5rem' }">
        <img src="/img/planete/planete-2.svg" alt="Planet" class="w-48 h-48 md:w-72 md:h-72 mx-auto transform transition-transform duration-300 hover:scale-110" ref="planetImage" />
  
        <!-- Task List -->
        <div class="w-full sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg mx-auto">
          <ul class="space-y-4">
            <li v-for="task in tasks" :key="task.id" class="bg-[#517f89] rounded-xl p-2 flex justify-between items-center shadow-lg transition-transform duration-300 hover:scale-105">
              <div class="flex items-center">
                <h2 class="text-white text-sm md:text-base">{{ task.title }}</h2>
                <span class="text-[#5ff8f1] text-sm font-bold ml-8 mr-8">{{ task.points }} TH</span>
              </div>
              <a :href="task.url" target="_blank" rel="noopener noreferrer" class="px-5 py-1 bg-blue-500 text-white rounded-full hover:bg-[#90b9c2] transition duration-300">
                Start
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Navbar />
    </div>
  </template>
  
  <script>
  import UserTasksService from "@/api/service/userTasksService";
  import Header from "@/components/Header.vue";
  import Navbar from "@/components/Navbar.vue";
  
  export default {
    components: {
      Header,
      Navbar,
    },
    data() {
      return {
        tasks: [],
      };
    },
    mounted() {
      this.getTasks();
    },
    methods: {
      async getTasks() {
        try {
          const response = await UserTasksService.getTasks();
          if (response) {
            this.tasks = response;
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des tâches:", error);
        }
      },
    },
  };
  </script>