import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import GameRules from "@/views/GameRules.vue";
import StartPlaying from "@/views/StartPlaying.vue";
import Referal from "@/views/Referal.vue";
import Tasks from "@/views/Tasks.vue";
import Airdrop from "@/views/Airdrop.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/game-rules",
    name: "game-rules",
    component: GameRules,
  },
  {
    path: "/start",
    name: "start",
    component: StartPlaying,
    meta: { requiresAuth: true },
  },
  {
    path: "/referal",
    name: "referal",
    component: Referal,
    meta: { requiresAuth: true },
  },
  {
    path: "/task",
    name: "task",
    component: Tasks,
    meta: { requiresAuth: true },
  },
  {
    path: "/airdrop",
    name: "airdrop",
    component: Airdrop,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function isAuthenticated() {
  // Vérifie si un token d'authentification existe dans la sessionStorage
  return !!sessionStorage.getItem("authToken");
}

// Guard global pour protéger les pages
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isAuthenticated()) {
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
