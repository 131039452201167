import axios from "axios";
import axiosClient from "../axios";


class UserServices {
    async userLogin() {

        try {
            fetch("http://localhost:8080/send-data", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Erreur réseau : " + response.statusText);
                    }
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.includes("application/json")) {
                        return response.json();
                    } else {
                        throw new Error("Réponse du serveur n'est pas en JSON.");
                    }
                })
                .then((data) => {
                    console.log("Réponse reçue:", data);
                })
                .catch((error) => {
                    console.error("Il y a eu un problème avec la requête fetch:", error);
                });
        } catch (error) {
            console.error("Erreur lors de la requête GET :", error);
        }

    }
    // Ajouter une récompense
    async userCollectReward(data) {
        console.log(data);

        try {
            const response = await axiosClient.post("/add-reward", data);
            if (response.status === 200 || response.status === 201) {
                return response.data;
            } else {
                console.log(response.data.message);
                throw new Error('Erreur lors de la validation : ' + response.data.message);
            }
        } catch (error) {
            if (error?.code === "ERR_NETWORK" || error?.message === "Network Error") {
                console.log('Erreur de réseau :', error.message);
            } else if (error.response && error.response.data && error.response.data.error) {
                console.log('Erreur du serveur :', error.response.data.error);
            } else {
                console.log('Erreur inconnue :', error.message);
            }
        }

    }
}

export default UserServices 