<template>
<div class="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32" :style="{
    backgroundImage: 'url(/img/throwbit_bot_background.svg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }">
    <div class="absolute top-0 left-0 right-0 flex justify-between items-center p-6">
        <a href="/" class="flex items-center text-white text-lg font-semibold hover:text-gray-300 transition duration-300">
            <i class="fas fa-arrow-left mr-2"></i>
            Back
        </a>
    </div>

    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="text-center mb-12">
            <h2 class="text-4xl font-extrabold tracking-tight text-white sm:text-6xl drop-shadow-lg font-[Poppins] animate-fadeIn">How to Play ?</h2>
            <p class="mt-6 text-lg leading-8 text-gray-300 drop-shadow-md tracking-wide font-[Lora] animate-fadeIn">
                Discover how to play and enjoy our exciting game. Swipe left or right to explore the game mechanics, features, and tips!
            </p>
        </div>
        <div class="mt-16 overflow-x-auto">
            <div class="flex space-x-6 animate-slideIn">
                <!-- Slide 1 -->
                <div class="relative flex-none w-80 h-80 bg-gray-800 rounded-lg p-6 text-center text-white shadow-lg transform transition-transform duration-500 hover:scale-105">
                    <h2 class="text-4xl font-extrabold tracking-tight text-white sm:text-6xl drop-shadow-lg font-[Poppins] animate-fadeIn">1 <i class="fas fa-arrow-right mr-2"></i></h2>
                    <img src="/img/planete-2.svg" alt="Planet Image" class="w-24 h-24 mx-auto mb-4 animate-spin">
                    <h3 class="text-xl font-semibold mb-2">Objective</h3>
                    <p class="text-sm">
                        Your goal is to tap on as many planets as you can within the time limit. Each tap gives you points and advances you in the game.
                    </p>
                </div>
                <!-- Slide 2 -->
                <div class="relative flex-none w-80 h-80 bg-gray-800 rounded-lg p-6 text-center text-white shadow-lg transform transition-transform duration-500 hover:scale-105">
                    <h2 class="text-4xl font-extrabold tracking-tight text-white sm:text-6xl drop-shadow-lg font-[Poppins] animate-fadeIn">2 <i class="fas fa-arrow-right mr-2"></i></h2>
                    <img src="/img/planete-3.svg" alt="Planet Image" class="w-24 h-24 mx-auto mb-4 animate-bounce">
                    <h3 class="text-xl font-semibold mb-2">Controls</h3>
                    <p class="text-sm">
                        Tap anywhere on the screen to interact with the planets. Use gestures to switch between different views and game modes.
                    </p>
                </div>
                <!-- Slide 3 -->
                <div class="relative flex-none w-80 h-80 bg-gray-800 rounded-lg p-6 text-center text-white shadow-lg transform transition-transform duration-500 hover:scale-105">
                    <h2 class="text-4xl font-extrabold tracking-tight text-white sm:text-6xl drop-shadow-lg font-[Poppins] animate-fadeIn">3</h2>
                    <img src="/img/planete-4.svg" alt="Planet Image" class="w-24 h-24 mx-auto mb-4 animate-pulse">
                    <h3 class="text-xl font-semibold mb-2">Tips</h3>
                    <p class="text-sm">
                        Try to tap on the rare planets for extra points. Keep an eye on the timer and aim for high scores to climb the leaderboard.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "@/api/axios.js";

export default {
    data() {
        return {

        };
    },
    methods: {

    }
};
</script>
