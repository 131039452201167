<template>
<nav class="fixed bottom-2 left-0 right-0 bg-[#517f89] p-1 shadow-lg rounded-lg mx-auto w-[85%] md:w-[70%] z-20 flex justify-around items-center">
    <ul class="flex justify-between items-center w-full">
        <!-- Home -->
        <li class="flex flex-col items-center">
            <router-link to="/start" class="flex flex-col items-center p-2 rounded-lg transition-transform duration-300 hover:scale-105 relative text-[#ffffff] text-center" :class="{ 'bg-[#7eb5c1] text-[#ffffff]': $route.path === '/start' }">
                <i class="fas fa-home text-sm"></i>
                <span class="text-[0.70rem] font-medium mt-1">Home</span>
            </router-link>
        </li>
        <!-- Friends -->
        <li class="flex flex-col items-center">
            <router-link to="/referal" class="flex flex-col items-center p-2 rounded-lg transition-transform duration-300 hover:scale-105 relative text-[#ffffff] text-center" :class="{ 'bg-[#7eb5c1] text-[#ffffff]': $route.path === '/referal' }">
                <i class="fas fa-user-friends text-sm"></i>
                <span class="text-[0.70rem] font-medium mt-1">Friends</span>
            </router-link>
        </li>
        <!-- Tasks -->
        <li class="flex flex-col items-center">
            <router-link to="/task" class="flex flex-col items-center p-2 rounded-lg transition-transform duration-300 hover:scale-105 relative text-[#ffffff] text-center" :class="{ 'bg-[#7eb5c1] text-[#ffffff]': $route.path === '/task' }">
                <i class="fas fa-tasks text-sm"></i>
                <span class="text-[0.70rem] font-medium mt-1">Tasks</span>
            </router-link>
        </li>
        <!-- Airdrop -->
        <li class="flex flex-col items-center">
            <router-link to="/airdrop" class="flex flex-col items-center p-2 rounded-lg transition-transform duration-300 hover:scale-105 relative text-[#ffffff] text-center" :class="{ 'bg-[#7eb5c1] text-[#ffffff]': $route.path === '/airdrop' }">
                <i class="fas fa-gift text-sm"></i>
                <span class="text-[0.70rem] font-medium mt-1">Airdrop</span>
            </router-link>
        </li>
    </ul>
</nav>
</template>

<script>
export default {
    name: "Navigation",
};
</script>
