import axios from 'axios';

const axiosClient = axios.create({
    baseURL: 'http://82.112.240.108:3000/api',
    headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',  // Désactive le cache côté client
        'Pragma': 'no-cache',         // Désactive le cache côté client
        'Expires': '0'                // Expire immédiatement
    }
});

export default axiosClient;
