<template>
<!-- Header section with "Cancel" and the three-dot button -->
<div class="absolute top-0 left-0 right-0 flex justify-between items-center p-6">
    <a href="/" class="text-white text-lg font-semibold hover:text-gray-300 transition duration-300">Cancel</a>
    <div class="text-center text-lg font-semibold text-white">
        ThrowCoin <span class="text-sm block font-normal">bot</span>
    </div>

    <!-- Bouton avec menu déroulant -->
    <div class="relative">
        <button @click="toggleDropdown" class="text-white p-2 rounded-full hover:bg-gray-800 transition duration-300">
            <i class="fas fa-ellipsis-h text-2xl"></i>
        </button>

        <!-- Menu déroulant -->
        <div v-if="isDropdownOpen" class="absolute right-0 mt-2 w-48 bg-gray-800 text-white rounded-lg shadow-lg py-2">
            <a href="/game-rules" class="block px-4 py-2 hover:bg-gray-700 rounded transition duration-300">Game Rules
            </a>

        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            isDropdownOpen: false, 
        };
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
    },
};
</script>

<style scoped>
/* Ajoute un style pour fermer le menu quand on clique ailleurs */
body {
    position: relative;
}
</style>
