import axiosClient from "../axios"; // Assurez-vous que le chemin est correct

class UserReferalService {

    static async getReferralCode(userId) {
        try {
            const response = await axiosClient.get(`/users/${userId}/referral-code`);
            if (response.status === 200) {
                return response.data; 
            } else {
                throw new Error(`Erreur lors de la récupération du lien de parrainage : ${response.data.message}`);
            }
        } catch (error) {
            console.error("Erreur dans getReferralCode:", error);
            throw error;
        }
    }

    static async getReferredUsers(referralCode) {
        try {
            const response = await axiosClient.get(`/users/${referralCode}/referred-users`);
            if (response.status === 200) {
                return response.data; 
            } else {
                throw new Error(`Erreur lors de la récupération des utilisateurs référés : ${response.data.message}`);
            }
        } catch (error) {
            console.error("Erreur dans getReferredUsers:", error);
            throw error;
        }
    }
}

export default UserReferalService;
